.App {
  text-align: center;
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-logo {
  width: 50%; /* Adjust this as needed */
  max-width: 1000px; /* Adjust this as needed */
}

.leaflet-control-attribution {
  background: black !important;
  color: grey !important;
}

.leaflet-control-attribution a {
  color: grey !important;
}


/* App.css */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}

.scraper-status {
  text-transform: uppercase;
  color: rgb(185,44,10);
  font-weight: 900;
  font-family: monospace;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  letter-spacing: .1px;
}

.scraper-blurb {
  font-family: monospace;
  white-space: nowrap;
  letter-spacing: .1px;
  font-size: 18px;
} 

.typewriter-1 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  animation: typing .3s steps(15, end);
  animation-fill-mode: backwards;
}

.typewriter-2 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  animation: typing .3s steps(15, end) .3s;
  animation-fill-mode: backwards;
}

.typewriter-3 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  animation: typing .3s steps(15, end) .6s;
  animation-fill-mode: backwards;
}

.reverse-typewriter-1 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  animation: typing-backwards .2s steps(15, end) .3s;
  animation-fill-mode: forwards;
}

.reverse-typewriter-2 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  animation: typing-backwards .2s steps(15, end) .1s;
  animation-fill-mode: forwards;
}

.reverse-typewriter-3 {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  animation: typing-backwards .1s steps(15, end);
  animation-fill-mode: forwards;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typing effect */
@keyframes typing-backwards {
  from { width: 100% }
  to { width: 0% }
}