.richTextWrapper {
    border: 1px solid lightgrey;
    border-radius: 28px;
    width: -webkit-fill-available;
    padding: var(--spacing-2) 0;
}
.richTextWrapper h2 {
    text-align: center;
    margin: 32px 0;
}
.richTextWrapper > p {
    padding: 24px 16px;
    word-wrap: break-word;
}
.richTextWrapper hr {
    border: 0.5px solid lightgrey;
}

.richTextWrapper ol li {
    padding: 24px 16px;
    word-wrap: break-word;
    list-style-position: inside;
}

.richTextWrapper ol li p {
    display: inline;
}